import React from 'react';
import HomeSection from './components/HomeSection';
import PresentationSection from './components/PresentationSection';
import ServicesSection from './components/ServicesSection';
import ReferencesSection from './components/ReferencesSection';
import ContactSection from './components/ContactSection';
import NavBar from './components/NavBar';

const App = () => {
  return (
    <div className="App">
      <NavBar />
      <div className="pt-14">
        <HomeSection />
        <PresentationSection />
        <ServicesSection />
        <ReferencesSection />
        <ContactSection />
      </div>
    </div>
  );
};

export default App;
