import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWrench, faBolt, faTools } from '@fortawesome/free-solid-svg-icons';

const ServicesSection = () => {
  return (
    <section id="services" className="min-h-screen py-12 bg-gray-100">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-extrabold text-gray-900">Nos services</h2>
          <p className="text-lg text-gray-700 mt-4">Découvrez nos services de maintenance mécanique et électrique des groupes électrogènes.</p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <h3 className="text-2xl font-bold mb-4">Maintenance mécanique du groupe électrogène</h3>
            <ul className="list-inside list-disc mt-4">
              <li className="flex items-center mb-2">
                <FontAwesomeIcon icon={faWrench} className="mr-2 text-blue-600" />
                Détacher les caractéristiques des parties distinctes mécaniques (pompe d’injection, courroies, démarrage, etc.)
              </li>
              <li className="flex items-center mb-2">
                <FontAwesomeIcon icon={faWrench} className="mr-2 text-blue-600" />
                Maintenance, la réparation du système de refroidissement, radiateur, courroie, pompe d’eau, etc.
              </li>
              <li className="flex items-center mb-2">
                <FontAwesomeIcon icon={faWrench} className="mr-2 text-blue-600" />
                Maintenance, réparation de toute partie endommagée du groupe électrogène (culasse, manivelle, pompe d’injection, etc.)
              </li>
              <li className="flex items-center mb-2">
                <FontAwesomeIcon icon={faWrench} className="mr-2 text-blue-600" />
                Changement de l’huile et des filtres
              </li>
              <li className="flex items-center mb-2">
                <FontAwesomeIcon icon={faWrench} className="mr-2 text-blue-600" />
                Garder la propreté de filtres à air et les changer si nécessaire
              </li>
              <li className="flex items-center mb-2">
                <FontAwesomeIcon icon={faWrench} className="mr-2 text-blue-600" />
                Vérification et changement des filtres à gasoil si nécessaire
              </li>
            </ul>
          </div>
          <div>
            <h3 className="text-2xl font-bold mb-4">Maintenance électrique du groupe électrogène</h3>
            <ul className="list-inside list-disc mt-4">
              <li className="flex items-center mb-2">
                <FontAwesomeIcon icon={faBolt} className="mr-2 text-blue-600" />
                Vérification des ampèremètres, tensiomètre, fréquencemètre et les changer si nécessaire
              </li>
              <li className="flex items-center mb-2">
                <FontAwesomeIcon icon={faBolt} className="mr-2 text-blue-600" />
                Vérification de l’isolement et de la continuité des conducteurs électriques
              </li>
              <li className="flex items-center mb-2">
                <FontAwesomeIcon icon={faBolt} className="mr-2 text-blue-600" />
                Vérification des indicateurs de sécurité (huile et température)
              </li>
              <li className="flex items-center mb-2">
                <FontAwesomeIcon icon={faBolt} className="mr-2 text-blue-600" />
                Vérification du stator des bobines inductrices et enduit
              </li>
              <li className="flex items-center mb-2">
                <FontAwesomeIcon icon={faBolt} className="mr-2 text-blue-600" />
                Définition de l’ordre des phases du groupe électrogène avec le secteur
              </li>
            </ul>
            <h4 className="text-xl font-bold mt-8 mb-4">Batterie et charge</h4>
            <ul className="list-inside list-disc mt-4">
              <li className="flex items-center mb-2">
                <FontAwesomeIcon icon={faTools} className="mr-2 text-blue-600" />
                Vérification d’électrolytes et ajout de l’eau distillée si nécessaire
              </li>
              <li className="flex items-center mb-2">
                <FontAwesomeIcon icon={faTools} className="mr-2 text-blue-600" />
                Vérification du fonctionnement de la batterie et la changer si nécessaire
              </li>
              <li className="flex items-center mb-2">
                <FontAwesomeIcon icon={faTools} className="mr-2 text-blue-600" />
                Vérification et réparation de l’alternateur de charge de la batterie
              </li>
              <li className="flex items-center mb-2">
                <FontAwesomeIcon icon={faTools} className="mr-2 text-blue-600" />
                Lubrification des plots avec une graisse isolante
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
