import React from 'react';
import OrangeLogo from '../assets/images/orange_maroc.png';
import InwiLogo from '../assets/images/inwi.png';
import ComoliLogo from '../assets/images/comoli.jpg';
import IsofotonLogo from '../assets/images/isofoton.png';
import EuroLocLogo from '../assets/images/euroloc.jpg';
import FinatechLogo from '../assets/images/finatech_group.jpg';
import CircetLogo from '../assets/images/circet_maroc.jpg';
import CamusatLogo from '../assets/images/camusat.jpg';
import LinfraLogo from '../assets/images/linfra.jpg';
import OkatelLogo from '../assets/images/okatel.jpg';

const ReferencesSection = () => {
  return (
    <section id="references" className="min-h-screen py-12 bg-gray-100">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-extrabold text-gray-900">Nos références</h2>
          <p className="text-lg text-gray-700 mt-4">Nous collaborons avec plusieurs grandes entreprises.</p>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-5 gap-4 justify-items-center">
          <div className="p-4 bg-white rounded-lg shadow-lg flex flex-col items-center">
            <img src={OrangeLogo} alt="Orange Maroc" className="h-32 w-32 object-contain"/>
            <p className="mt-2 text-center text-sm text-gray-700">Orange Maroc</p>
          </div>
          <div className="p-4 bg-white rounded-lg shadow-lg flex flex-col items-center">
            <img src={InwiLogo} alt="Inwi" className="h-32 w-32 object-contain"/>
            <p className="mt-2 text-center text-sm text-gray-700">Inwi</p>
          </div>
          <div className="p-4 bg-white rounded-lg shadow-lg flex flex-col items-center">
            <img src={CircetLogo} alt="Circet Maroc" className="h-32 w-32 object-contain"/>
            <p className="mt-2 text-center text-sm text-gray-700">Circet Maroc</p>
          </div>
          <div className="p-4 bg-white rounded-lg shadow-lg flex flex-col items-center">
            <img src={ComoliLogo} alt="Comoli" className="h-32 w-32 object-contain"/>
            <p className="mt-2 text-center text-sm text-gray-700">Comoli</p>
          </div>
          <div className="p-4 bg-white rounded-lg shadow-lg flex flex-col items-center">
            <img src={IsofotonLogo} alt="Isofoton" className="h-32 w-32 object-contain"/>
            <p className="mt-2 text-center text-sm text-gray-700">Isofoton</p>
          </div>
          <div className="p-4 bg-white rounded-lg shadow-lg flex flex-col items-center">
            <img src={EuroLocLogo} alt="EuroLoc" className="h-32 w-32 object-contain"/>
            <p className="mt-2 text-center text-sm text-gray-700">EuroLoc</p>
          </div>
          <div className="p-4 bg-white rounded-lg shadow-lg flex flex-col items-center">
            <img src={FinatechLogo} alt="Finatech" className="h-32 w-32 object-contain"/>
            <p className="mt-2 text-center text-sm text-gray-700">Finatech group</p>
          </div>
          <div className="p-4 bg-white rounded-lg shadow-lg flex flex-col items-center">
            <img src={CamusatLogo} alt="Camusat" className="h-32 w-32 object-contain"/>
            <p className="mt-2 text-center text-sm text-gray-700">Camusat Maroc</p>
          </div>
          <div className="p-4 bg-white rounded-lg shadow-lg flex flex-col items-center">
            <img src={LinfraLogo} alt="Linfra" className="h-32 w-32 object-contain"/>
            <p className="mt-2 text-center text-sm text-gray-700">Linfra Maroc</p>
          </div>
          <div className="p-4 bg-white rounded-lg shadow-lg flex flex-col items-center">
            <img src={OkatelLogo} alt="Okatel" className="h-32 w-32 object-contain"/>
            <p className="mt-2 text-center text-sm text-gray-700">Okatel</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ReferencesSection;
