import React, { useState } from 'react';

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleScroll = (event, id) => {
    event.preventDefault();
    const targetElement = document.querySelector(id);
    const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY - 80; // Adjusted for navbar height
    const startPosition = window.scrollY;
    const distance = targetPosition - startPosition;
    const duration = 1000;
    let start = null;

    const animateScroll = (timestamp) => {
      if (!start) start = timestamp;
      const progress = timestamp - start;
      const r = progress / duration;
      const easeInOutQuad = r < 0.5 ? 2 * r * r : 1 - Math.pow(-2 * r + 2, 2) / 2;
      window.scrollTo(0, startPosition + distance * easeInOutQuad);
      if (progress < duration) {
        requestAnimationFrame(animateScroll);
      } else {
        window.scrollTo(0, targetPosition);
      }
    };

    requestAnimationFrame(animateScroll);
    setIsOpen(false);
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="navbar bg-base-100 shadow-md fixed top-0 left-0 right-0 z-50">
      <div className="container mx-auto px-4 py-2 flex justify-between items-center">
        <div className="flex items-center space-x-2">
          <img src={`${process.env.PUBLIC_URL}/Logo.png`} alt="Locsa Maroc" className="w-24" />
        </div>
        <div className="lg:hidden">
          <button className="btn btn-square btn-ghost" onClick={toggleMenu}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7" />
            </svg>
          </button>
        </div>
        <div className={`lg:flex ${isOpen ? 'dropdown-enter' : 'hidden'} transition-all duration-500 ease-in-out bg-base-100 w-full lg:w-auto lg:static absolute top-full left-0 right-0 lg:block`}>
          <ul className="menu menu-compact lg:menu-horizontal bg-base-100 lg:bg-transparent p-3 lg:p-0 rounded-box lg:rounded-none lg:flex lg:space-x-4 lg:ml-auto">
            <li><a href="#home" onClick={(e) => handleScroll(e, '#home')} className="block lg:inline-block px-3 py-2 rounded hover:bg-gray-200">Accueil</a></li>
            <li><a href="#presentation" onClick={(e) => handleScroll(e, '#presentation')} className="block lg:inline-block px-3 py-2 rounded hover:bg-gray-200">Présentation</a></li>
            <li><a href="#services" onClick={(e) => handleScroll(e, '#services')} className="block lg:inline-block px-3 py-2 rounded hover:bg-gray-200">Services</a></li>
            <li><a href="#references" onClick={(e) => handleScroll(e, '#references')} className="block lg:inline-block px-3 py-2 rounded hover:bg-gray-200">Références</a></li>
            <li><a href="#contact" onClick={(e) => handleScroll(e, '#contact')} className="block lg:inline-block px-3 py-2 rounded hover:bg-gray-200">Contact</a></li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
