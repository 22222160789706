import React from 'react';

const ContactSection = () => {
  return (
    <section id="contact" className="min-h-screen py-12 bg-gray-900 text-white">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-extrabold text-white">Contactez-nous</h2>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="space-y-4">
            <h3 className="text-xl font-bold">SIEGE SOCIAL TANGER :</h3>
            <p>RUE SENHAJA RESIDENCE HAMDOULAH APP N°, Tanger</p>
            <p><strong>Tél/fax:</strong> <a href="tel:+212539394717" className="text-blue-400">05 39 39 47 17</a></p>
            <p><a href="mailto:locsa@locsamaroc.ma" className="text-blue-400">locsa@locsamaroc.ma</a></p>
          </div>
          <div className="space-y-4">
            <h3 className="text-xl font-bold">SUCCURSALE CASABLANCA:</h3>
            <p>66 lotissement communal, App N°3 Sidi Maârouf, Casablanca</p>
            <p><strong>Tél:</strong> <a href="tel:+212522584527" className="text-blue-400">05 22 58 45 27</a></p>
            <p><a href="mailto:locsa@locsamaroc.ma" className="text-blue-400">locsa@locsamaroc.ma</a></p>
          </div>
          <div className="space-y-4">
            <h3 className="text-xl font-bold">Directeur Général :</h3>
            <p>Mr. Mohamed EL HAJAMI</p>
            <p><a href="mailto:mohamed.h@locsamaroc.ma" className="text-blue-400">mohamed.h@locsamaroc.ma</a></p>
          </div>
        </div>
        <div className="mt-12">
          <div className="text-center mb-6">
            <h3 className="text-2xl font-bold">Localisation</h3>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3234.1344458261185!2d-5.8124754!3d35.7712534!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd0b80ab11a0ab93%3A0x5b98a925a4d51d23!2sQ5CQ%2BG2X%2C%20Av.%20Sanhaja%2C%20Tanger%2090060!5e0!3m2!1sen!2sma!4v1680634423768!5m2!1sen!2sma"
              width="100%"
              height="400"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Tanger Location"
            ></iframe>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3323.9674098772914!2d-7.6494042!3d33.5147953!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda62da9f698197d%3A0x52b592136e4a3500!2s66%20Route%20Sidi%20Maarouf%2C%20Dar-el-Beida!5e0!3m2!1sen!2sma!4v1680687334461!5m2!1sen!2sma"
              width="100%"
              height="400"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="Casablanca Location"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
