import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBolt } from '@fortawesome/free-solid-svg-icons';
import imgCeo from '../assets/images/ceo.jpg';

const PresentationSection = () => {
  return (
    <section id="presentation" className="min-h-screen py-12 bg-gray-100">
      <div className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-extrabold text-gray-900">Présentation de LOCSA Maroc</h2>
          <p className="text-lg text-gray-700 mt-4">Avec plus de 30 ans d'expérience, nous sommes leaders dans la location et la maintenance de groupes électrogènes.</p>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div>
            <h3 className="text-2xl font-bold mb-4">À propos de nous</h3>
            <p className="mb-4">
              LOCSA Maroc est un leader dans la fourniture de services de location, d'installation, de maintenance et de surveillance de groupes électrogènes à travers le Maroc. Notre expertise garantit des solutions énergétiques fiables pour tous vos besoins, où que vous soyez.
            </p>
            <p className="mb-4">
              Notre gamme complète de groupes électrogènes et d'accessoires est conçue pour offrir sécurité et autonomie dans toutes les situations. Nous desservons divers secteurs, notamment les télécommunications, les entreprises électriques et industrielles, et les entreprises de construction.
            </p>
          </div>
          <div>
            <h3 className="text-2xl font-bold mb-4">Nos groupes électrogènes</h3>
            <p className="mb-4">Nous proposons une large gamme de groupes électrogènes adaptés à tous vos besoins :</p>
            <ul className="list-inside list-disc mt-4">
              <li className="flex items-center mb-2">
                <FontAwesomeIcon icon={faBolt} className="mr-2 text-blue-600" />
                Groupes électrogènes de 5 kVa à 400 kVa
              </li>
              <li className="flex items-center mb-2">
                <FontAwesomeIcon icon={faBolt} className="mr-2 text-blue-600" />
                Solutions personnalisées pour les opérateurs de télécom, les entreprises industrielles et électriques
              </li>
              <li className="flex items-center mb-2">
                <FontAwesomeIcon icon={faBolt} className="mr-2 text-blue-600" />
                Maintenance préventive et corrective
              </li>
              <li className="flex items-center mb-2">
                <FontAwesomeIcon icon={faBolt} className="mr-2 text-blue-600" />
                Service de dépannage 24h/24 et 7j/7
              </li>
            </ul>
          </div>
        </div>
        <div className="mt-8 bg-white p-8 rounded-lg shadow-lg flex flex-col lg:flex-row items-center">
          <div className="lg:w-2/3">
            <h2 className="text-3xl font-extrabold mb-4">Notre directeur général</h2>
            <p className="mb-4">
              LOCSA Maroc est dirigée par Mr. Mohamed EL HAJAMI, dont le leadership et la vision stratégique ont propulsé l'entreprise au premier rang des fournisseurs de solutions énergétiques au Maroc.
            </p>
          </div>
          <div className="lg:w-1/3 flex justify-center lg:justify-end">
            <img src={imgCeo} alt="Directeur Général" className="w-32 h-32 rounded-full lg:w-40 lg:h-40" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default PresentationSection;
