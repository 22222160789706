// HomeSection.js
import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import img1 from '../assets/images/gewithsite.jpg';
import img2 from '../assets/images/locsa1.jpg';
import img3 from '../assets/images/locsaold1.jpg';
import img4 from '../assets/images/locsaold2.jpg';
import img5 from '../assets/images/truckswithsite.jpg';

const HomeSection = () => {
  return (
    <section id="home" className="min-h-screen flex items-center bg-gradient-to-r from-blue-600 to-orange-500 text-white transition-all duration-500 ease-in-out w-full">
      <div className="flex flex-col lg:flex-row items-center justify-between w-full max-w-screen-xl mx-auto px-4">
        <div className="text-center lg:text-left lg:w-1/2 px-4">
          <h1 className="text-3xl lg:text-5xl font-extrabold drop-shadow-lg">Bienvenue chez <br/>LOCSA Maroc</h1>
          <p className="mt-6 lg:mt-4 text-base lg:text-lg">Nous fournissons des services de location, de maintenance de générateurs, de travaux électriques et de projets de télécommunications.</p>
        </div>
        <div className="w-full lg:w-1/2">
          <Carousel 
            showThumbs={false}
            showStatus={false}
            infiniteLoop={true}
            autoPlay={true}
            interval={3000}
            transitionTime={600}
          >
            <div className="p-2">
              <img src={img1} alt="Service 1" className="rounded-lg shadow-lg object-cover w-full h-64 md:h-72 lg:h-96"/>
            </div>
            <div className="p-2">
              <img src={img2} alt="Service 2" className="rounded-lg shadow-lg object-cover w-full h-64 md:h-72 lg:h-96"/>
            </div>
            <div className="p-2">
              <img src={img3} alt="Service 3" className="rounded-lg shadow-lg object-cover w-full h-64 md:h-72 lg:h-96"/>
            </div>
            <div className="p-2">
              <img src={img4} alt="Service 4" className="rounded-lg shadow-lg object-cover w-full h-64 md:h-72 lg:h-96"/>
            </div>
            <div className="p-2">
              <img src={img5} alt="Service 5" className="rounded-lg shadow-lg object-cover w-full h-64 md:h-72 lg:h-96"/>
            </div>
          </Carousel>
        </div>
      </div>
    </section>
  );
};

export default HomeSection;
